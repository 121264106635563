define("ember-localized-model/-private/serializers/localized", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ScopeSerializer extends _jsonApi.default {
    serializeAttribute(snapshot, json, key, attributes) {
      super.serializeAttribute(snapshot, json, key, attributes);
      const {
        localizedFields = []
      } = snapshot.record;
      if (localizedFields.includes(key)) {
        json.attributes[key] = snapshot.record.localizedObjects[key];
      }
    }
  }
  _exports.default = ScopeSerializer;
});
define("ember-validated-form/passed-or-default", ["exports", "@embroider/util", "@embroider/macros/runtime", "@embroider/macros/es-compat2"], function (_exports, _util, _runtime, _esCompat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = passedOrDefault;
  function passedOrDefault(componentName) {
    return function (target, property) {
      return {
        get() {
          return (0, _util.ensureSafeComponent)(this.args[property] ?? (0, _esCompat.default)(require((0, _runtime.config)("/app/node_modules/ember-validated-form")[componentName])).default, this);
        }
      };
    };
  }
});
define("ember-validated-form/components/validated-input/types/checkbox", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isUikit"))}}
    <@labelComponent
      class="{{if @isValid 'uk-text-success'}} {{if @isInvalid 'uk-text-danger'}}"
    >
      <input
        class="uk-checkbox uk-margin-small-right"
        type="checkbox"
        name={{@name}}
        id={{@inputId}}
        disabled={{@disabled}}
        checked={{@value}}
        {{on "input" this.onUpdate}}
        ...attributes
      />
    </@labelComponent>
  {{else if (macroCondition (macroGetOwnConfig "isBootstrap"))}}
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input
          {{if @isValid 'is-valid'}}
          {{if @isInvalid 'is-invalid'}}"
        type="checkbox"
        name={{@name}}
        id={{@inputId}}
        disabled={{@disabled}}
        checked={{@value}}
        {{on "input" this.onUpdate}}
        ...attributes
      />
      <@labelComponent class="custom-control-label" />
    </div>
  {{else}}
    <input
      type="checkbox"
      name={{@name}}
      id={{@inputId}}
      disabled={{@disabled}}
      checked={{@value}}
      {{on "input" this.onUpdate}}
      ...attributes
    />
    <@labelComponent />
  {{/if}}
  */
  {
    "id": "f80Nj39+",
    "block": "[[[1,\"  \"],[8,[30,1],[[16,0,[29,[[52,[30,2],\"uk-text-success\"],\" \",[52,[30,3],\"uk-text-danger\"]]]]],null,[[\"default\"],[[[[1,\"\\n    \"],[11,\"input\"],[24,0,\"uk-checkbox uk-margin-small-right\"],[24,4,\"checkbox\"],[16,3,[30,4]],[16,1,[30,5]],[16,\"disabled\",[30,6]],[16,\"checked\",[30,7]],[17,8],[4,[38,1],[\"input\",[30,0,[\"onUpdate\"]]],null],[12],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@labelComponent\",\"@isValid\",\"@isInvalid\",\"@name\",\"@inputId\",\"@disabled\",\"@value\",\"&attrs\"],false,[\"if\",\"on\"]]",
    "moduleName": "ember-validated-form/components/validated-input/types/checkbox.hbs",
    "isStrictMode": false
  });
  let CheckboxComponent = _exports.default = (_class = class CheckboxComponent extends _component2.default {
    onUpdate(event) {
      event.preventDefault();
      this.args.update(event.target.checked);
      this.args.setDirty();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CheckboxComponent);
});
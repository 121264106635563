define("ember-validated-form/components/validated-input/hint", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <small
    id={{@id}}
    class={{class-list
      (if (macroCondition (macroGetOwnConfig "isUikit")) "uk-text-muted")
      (if
        (macroCondition (macroGetOwnConfig "isBootstrap")) "form-text text-muted"
      )
    }}
    ...attributes
  >{{yield}}{{@hint}}</small>
  */
  {
    "id": "MHUScP2T",
    "block": "[[[11,\"small\"],[16,1,[30,1]],[16,0,[28,[37,0],[\"uk-text-muted\",[27]],null]],[17,2],[12],[18,4,null],[1,[30,3]],[13]],[\"@id\",\"&attrs\",\"@hint\",\"&default\"],false,[\"class-list\",\"yield\"]]",
    "moduleName": "ember-validated-form/components/validated-input/hint.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
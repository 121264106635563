define("ember-localized-model/index", ["exports", "ember-localized-model/-private/decorators/localized-attr", "ember-localized-model/-private/models/localized", "ember-localized-model/-private/serializers/localized"], function (_exports, _localizedAttr, _localized, _localized2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "LocalizedModel", {
    enumerable: true,
    get: function () {
      return _localized.default;
    }
  });
  Object.defineProperty(_exports, "LocalizedSerializer", {
    enumerable: true,
    get: function () {
      return _localized2.default;
    }
  });
  Object.defineProperty(_exports, "localizedAttr", {
    enumerable: true,
    get: function () {
      return _localizedAttr.default;
    }
  });
});
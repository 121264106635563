define("ember-localized-model/-private/decorators/localized-attr", ["exports", "@ember/application", "@ember/utils", "@ember-data/model"], function (_exports, _application, _utils, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(...args) {
    const [target, name] = args;

    // We cannot set the `defaultValue` directly to `{}` because of:
    // Error: Assertion Failed: Non primitive defaultValues are not supported because they are shared
    // between all instances. If you would like to use a complex object as a default value please
    // provide a function that returns the complex object.
    const attrComputed = (0, _model.attr)({
      defaultValue: () => ({})
    })(...args);
    const {
      get: getter,
      set: setter
    } = attrComputed;
    target.localizedFields = [...(target.localizedFields || []), name];
    attrComputed.get = function () {
      const value = getter.call(this);
      if (!value) {
        return;
      }
      const {
        localizedModel: {
          allowAnyFallback = false,
          fallbacks = []
        } = {}
      } = (0, _application.getOwner)(this).resolveRegistration("config:environment");
      if (value[this.getFieldLocale()] || !allowAnyFallback && !fallbacks.length) {
        return value[this.getFieldLocale()];
      }
      let key = fallbacks.find(key => !(0, _utils.isEmpty)(value[key]));
      if (!key && allowAnyFallback) {
        key = Object.keys(value).find(key => !(0, _utils.isEmpty)(value[key]));
      }
      return value[key];
    };
    attrComputed.set = function (value) {
      let attribute = getter.call(this);
      if (!attribute) {
        attribute = this.intl.locales.reduce((accumulator, currentValue) => ({
          ...accumulator,
          [currentValue]: ""
        }), {});
      }
      attribute[this.getFieldLocale()] = value;
      setter.call(this, attribute);
    };

    // Use the getter and setter for the original property so both
    // the Object and the previous getter and setter access the same value.
    Object.defineProperty(target, `_${name}`, {
      get: getter,
      set: setter,
      enumerable: false
    });
    return attrComputed;
  }
});